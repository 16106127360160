import * as React from "react";
import { debounce, get } from "lodash-es";
import { DEBOUNCE_SEARCH_TIME } from "../constants";
import { isStringEmpty } from "@ic-anywhere/ic-utils";
import { SEARCH_MIN_CHAR_LIMIT } from "../constants/contactsPicker.constants";
import { isMatchingMinCharLimit } from "../../utils/strings/stringUtils";
import useContactDbRepository from "../../api/useContactDbRepository";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { tenMinutesInMs } from "@ic-anywhere/ic-dal";

export const useFetchGenericByTermQuery = (
  fetchByTerm: (term, ...args) => Promise<any[]>,
  term: string,
  enabled = true
): UseQueryResult<any[], string> => {
  const repo = useContactDbRepository();

  const key = ["getByIds", term];

  return useQuery<any[], string>(
    key,
    ({ signal }) => fetchByTerm(term, repo, signal),

    {
      enabled: enabled && term?.length >= 2,
      cacheTime: tenMinutesInMs,
    }
  );
};

export const useFetchGeneric = <TResult>(
  fetchByTerm: (term, ...args) => Promise<TResult[]>,
  fetchByIds: () => Promise<TResult[]>,
  fields?: string[],
  ids?: string[],
  minCharLimit = SEARCH_MIN_CHAR_LIMIT,
  debounceSearchTime = DEBOUNCE_SEARCH_TIME
): [TResult[], boolean, boolean, string, (term: string, ...args) => void, string] => {
  const [items, setItems] = React.useState<TResult[]>([]);
  const [filteredItems, setFilteredItems] = React.useState<TResult[]>([]);
  const [hasError, setHasError] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [searchTerm, setSearchTerm] = React.useState<string>("");

  const { data, isFetching } = useFetchGenericByTermQuery(fetchByTerm, searchTerm, !ids || ids?.length === 0);

  React.useEffect(() => {
    if (!!data && Array.isArray(data)) {
      setFilteredItems(data);
    }
  }, [data]);

  React.useEffect(() => {
    setIsLoading(isFetching);
  }, [isFetching]);

  const matchByAllFields = (obj: TResult, term: string, fieldsArray?: string[]): boolean => {
    return fieldsArray?.reduce<boolean>((acc, curr) => acc || matchByField(obj, term, curr), false) ?? false;
  };
  const matchByField = (obj: TResult, term: string, field: string): boolean => {
    const value = get(obj, field, "");
    return typeof value === "string" && !isStringEmpty(value)
      ? value.toLocaleLowerCase().includes(term?.toLocaleLowerCase())
      : false;
  };

  const handleError = (message: string | undefined): void => {
    setErrorMessage(message ?? "Failed to fetch");
    setIsLoading(false);
    setHasError(false);
  };

  React.useEffect(() => {
    if (ids?.length) {
      fetchByIds()
        .then(result => {
          setItems(result);
          setIsLoading(false);
        })
        .catch(err => {
          handleError(err?.message);
        });
    }
  }, [fetch, JSON.stringify(ids)]);

  React.useEffect(() => {
    if (ids?.length) {
      setFilteredItems(items);
      setSearchTerm("");
    }
  }, [items, JSON.stringify(ids)]);

  const onTermChange = debounce((term: string, ...args) => {
    setSearchTerm(term);
    if (ids?.length) {
      setSearchTerm(term);
      const filtered = items?.filter(item => matchByAllFields(item, term, fields));
      setFilteredItems(filtered);
    } else {
      if (term?.length === 0 || !isMatchingMinCharLimit(term, minCharLimit)) {
        setFilteredItems([]);
        return;
      }
    }
  }, debounceSearchTime);

  return [filteredItems, isLoading, hasError, errorMessage, onTermChange, searchTerm];
};
