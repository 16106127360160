import { endsWith, join, map, size, split, toUpper } from "lodash-es";
import { diacriticsMap } from "./diacriticsMap.const";

export const removeDiacritics = (value: string): string =>
  diacriticsMap.reduce((acc, current) => acc.replace(current.letters, current.base), value);

export const removeInValidChars = (value: string): string => (value ?? "").replace(/[^\x20-\xFF]/g, "");
export const removeSuccessiveSpaces = (value: string): string => (value ?? "").replace(/\s+/g, " ");

export const sanitizeSearchTerm = (value: string): string =>
  removeInValidChars(removeDiacritics(removeSuccessiveSpaces(value ?? "")));

export const isStringEmpty = (value?: string | null): boolean =>
  value === undefined ||
  value === null ||
  value?.trim?.() === "" ||
  value?.trim?.() === "undefined" ||
  value?.trim?.() === "null";

export const isMatchingMinCharLimit = (term: string, minCharLimit: number): boolean =>
  size(sanitizeSearchTerm(term)) >= minCharLimit;

export const getInitials = (value = ""): string => {
  return join(
    map(split(value, " "), (name = "") => toUpper(name.charAt(0))),
    " "
  );
};

export const isStringEqual = (value?: string, other?: string, trimSpaces = true, insensitive = false): boolean => {
  if (!value && !other) {
    return true;
  }
  const { value1, value2 } = trimSpaces
    ? { value1: value?.trim?.(), value2: other?.trim?.() }
    : { value1: value, value2: other };

  return insensitive ? value1 === value2 : value1?.toLocaleLowerCase?.() === value2?.toLocaleLowerCase?.();
};

export const isContainSubString = (value: string, other: string, trimSpaces = true, ignoreCase = true): boolean => {
  const { value1, value2 } = trimSpaces
    ? { value1: value?.trim(), value2: other?.trim() }
    : { value1: value, value2: other };
  return ignoreCase ? value1.toLowerCase().includes(value2.toLowerCase()) : value1.includes(value2);
};

export const pluralize = (word: string, n = 10): string => {
  if (endsWith(word, "s") || n <= 1) {
    return word;
  }

  if (endsWith(word, "y")) {
    return `${word.slice(0, -1)}ies`;
  }
  return `${word}s`;
};
