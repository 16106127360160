import * as React from "react";
import { isStringEmpty } from "../../utils/strings/stringUtils";
import { EmitOnChangeMode } from "../typings";
import { get, isEmpty } from "lodash-es";

type UsePickerSelectedResult<R> = [R | null, R[], (item: R | null) => void];

export function isIdEmpty<S>(id?: S): boolean {
  return typeof id === "string" ? isStringEmpty(id) : !id;
}

export function shouldNotFetch<R, S>(id?: S, item?: R | null): boolean {
  return id === get(item, "id");
}

// S refers to the selected-id or the readonly-id, it is often a string, but could be a <ThirdId> like in MaestroSinglePicker
export const useSingleSelectPicker = <R, S = string>(
  fetchBy: (input: S) => Promise<R | null>,
  emitMode?: EmitOnChangeMode,
  onChange?: (input: R | null) => void,
  onReady?: () => void,
  selected?: S | null,
  readonly?: S
): UsePickerSelectedResult<R> => {
  const [selectedItem, setSelectedItem] = React.useState<R | null>(null);
  const [readOnlyItem, setReadOnlyItem] = React.useState<R | null>(null);

  React.useEffect(() => {
    if (shouldNotFetch(selected, selectedItem)) {
      return;
    }

    if (isIdEmpty(selected)) {
      setSelectedItem(null);
      handleOnChange(null);
      return;
    }

    fetchBy(selected!).then(result => {
      setSelectedItem(result ?? null);
      handleOnChange(result);
    });
  }, [fetchBy, selected, emitMode]);

  React.useEffect(() => {
    if (shouldNotFetch(readonly, readOnlyItem)) {
      return;
    }

    if (isIdEmpty(readonly)) {
      setReadOnlyItem(null);
      return;
    }

    fetchBy(readonly!).then(result => {
      setReadOnlyItem(result ?? null);
    });
  }, [fetchBy, readonly, emitMode]);

  React.useEffect(() => {
    onReady?.();
  }, [onReady]);

  const handleOnChange = (item: R | null) => {
    if (emitMode === "always" || (emitMode === "not-empty" && !isEmpty(item))) {
      onChange?.(item);
    }
  };

  // manual selection
  const onSelect = (item: R | null): void => {
    setSelectedItem(item);
    onChange?.(item);
  };

  // in order to meet Select.AsyncSingleSelect spec, readOnlyItems must be an array
  // but IMO there is no reason for that. we should fix Select.AsyncSingleSelect to take a single value for readOnlyItem,
  return [selectedItem, readOnlyItem ? [readOnlyItem] : [], onSelect];
};
