import {
  AddressDto,
  ClientDto,
  ClientIdsDto,
  DomainNameDto,
  privateClientDto,
} from "../../api/accounts/accounts.api.typings";
import { find, includes, map, some, uniq } from "lodash-es";
import { AccountTeam, AccountTeamViewerWidget, AccountViewerWidget, AccountWidget } from "../typings/accounts.typings";
import { AccountDisplayOption } from "../typings";
import { AccountDTO } from "@ic-anywhere/ic-dal";

export const mapToDomainName = (domains: DomainNameDto[]): string => {
  const domain = find(domains, ({ status }) => status === "Authorized" || status === "Unknown");
  return domain?.domainName ?? "";
};

export const formatAccountName = (name: string, accountLevel?: string, country = "", bdrId = "", city = ""): string => {
  const accountLevelString = accountLevel ? ` (${accountLevel})` : "";
  const bdrIdString = bdrId ? `BDR ${bdrId}` : "";
  const extra = city || bdrIdString;
  const extraName = extra ? `- ${extra}` : "";
  return `${name}${accountLevelString} ${country} ${extraName}`.trim();
};

export const mapToAccountWidget = (
  clientDto: ClientDto | AccountDTO,
  displayOption: AccountDisplayOption = "none",
  isSuggestion = false
): AccountWidget => {
  const bdrCommercialId = clientDto?.clientIds?.bdrCommercialId?.id;
  const bdrCommercialLevel = clientDto?.clientIds?.bdrCommercialId?.level;
  const bdrLegalId = clientDto?.clientIds?.bdrLegalId?.id;
  const bdrLegalLevel = clientDto?.clientIds?.bdrLegalId?.level;
  const city = clientDto.mainAddress?.city;

  return {
    id: clientDto?.id,
    icId: clientDto?.id,
    rctId: clientDto?.clientIds?.rctId,
    siren: (clientDto?.clientIds as ClientIdsDto)?.siren,
    type: clientDto?.type,

    name: clientDto?.name,
    fullCommercialName: formatAccountName(
      clientDto?.name,
      clientDto?.clientIds?.internalId?.level,
      clientDto?.country?.iso3,
      displayOption === "bdr-commercial-id" ? bdrCommercialId : displayOption === "bdr-legal-id" ? bdrLegalId : "",
      displayOption === "city" ? city : ""
    ),
    bdrMnemonic: clientDto?.bdrId?.bdrMnemonic,

    bdrCommercialId,
    bdrCommercialLevel,
    bdrLegalId,
    bdrLegalLevel,

    countryIso3: clientDto?.country?.iso3,
    countryName: clientDto?.country?.country,

    parentGroup: clientDto?.clientIds?.root?.name,
    parentGroupId: clientDto?.clientIds?.root?.id,
    parentGroupLevel: clientDto?.clientIds?.root?.level,

    level: clientDto?.clientIds?.internalId?.level, // ic level

    typology: clientDto?.typology,
    mainAddress: clientDto?.mainAddress as AddressDto,
    dec: clientDto?.customerRepresentative?.dec,
    dr: clientDto?.customerRepresentative?.dr,
    isInMyPortfolio: clientDto?.portfolio?.isInMyPortfolio ?? false,
    isSuggestion,
  };
};

export const mapToAccountViewerWidget = (clientDto: ClientDto, domainName: string): AccountViewerWidget => {
  return {
    ...mapToAccountWidget(clientDto),
    domainName: domainName ?? "",
  };
};

export const mapToListAccountWidget = (
  clientDtoList: ClientDto[],
  displayOption?: AccountDisplayOption,
  isSuggestion?: boolean
): AccountWidget[] => map(clientDtoList, clientDto => mapToAccountWidget(clientDto, displayOption, isSuggestion)) ?? [];

const getPersonTeams = ({ coverage }: ClientDto, role: string) => {
  if (coverage) {
    return coverage?.filter(c => c.role === role)?.map(({ person }) => `${person.firstname} ${person.lastname}`);
  }

  return [];
};

const PCRUSegmentationLevelIds: string[] = [
  "d53cd8bf-eec5-4c91-bc95-ce5f1fd3a142",
  "c148749f-3922-41cf-bae7-5182266cc6d3",
  "118e7efe-73fe-4f0b-a5a1-f1576104ef05",
];
const PCRUSegmentationListId: string = "8b98e67e-8c53-4e61-8248-855767ab6a56";
export const mapToAccountTeamViewer = (
  privateClient: privateClientDto,
  client: ClientDto,
  selectedTeams: AccountTeam[]
): AccountTeamViewerWidget => {
  const teams = selectedTeams.reduce(
    (acc, { role }) => ({
      ...acc,
      [role]: uniq(getPersonTeams(client, role)),
    }),
    {}
  );

  const isPCRUSegmentation = some(
    map(client.tieringLists, ({ list, level }) => ({ listId: list.id, levelId: level.id })),
    ({ listId, levelId }) => listId === PCRUSegmentationListId && includes(PCRUSegmentationLevelIds, levelId)
  );

  return {
    pcru: privateClient.pcru,
    teams,
    isPCRUSegmentation,
  };
};
