import {
  buildUrlForEnv,
  consoleLogger,
  getServiceUrl,
  IcHttpRequestHeader,
  Logger,
  LoggerAppName,
  webLibLogger,
} from "@ic-anywhere/ic-dal";
import { getEnvironment } from "../common/environment";
import { Environment } from "@ic-anywhere/ic-utils";
import { stringifyUrlOptions } from "../utils/strings/stringify";

export const WIDGET_APP_NAME = "IC-Widgets";

export const buildUrlForWidgets = (
  inputPath: string,
  headers: IcHttpRequestHeader,
  params?: Record<string, any>
): string => {
  const env = getEnvironment();

  return buildUrlForEnv(env, inputPath, headers, params, stringifyUrlOptions);
};

export const isDevEnv = (env: Environment): boolean => ["test", "local", "development"].includes(env);
export const getWidgetsLogger = (env: Environment): Logger =>
  isDevEnv(env) ? consoleLogger : webLibLogger(WIDGET_APP_NAME as LoggerAppName, window.location.href);

export const getVnextCreateLink = (entity: "contacts" | "prospects"): string => {
  const url = getServiceUrl("Vnext", getEnvironment());
  return `${url}/v2/${entity}/new`;
};

export const allSettled = (promises: Promise<any>[]): Promise<any> => {
  const wrappedPromises = promises.map(p =>
    Promise.resolve(p).then(
      val => ({ status: "fulfilled", value: val }),
      err => ({ status: "rejected", reason: err })
    )
  );
  return Promise.all(wrappedPromises);
}
