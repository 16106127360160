import * as React from "react";
import { useWidgetConfiguration } from "@sg-widgets/react-core";
import { logMessage, WidgetEnv } from "../logger/logger";

interface Props {
  widgetName: string;
}

const WidgetTracker: React.FC<Props> = ({ widgetName }) => {
  const widgetConfiguration = useWidgetConfiguration();
  const env = widgetConfiguration.environment as WidgetEnv;
  React.useEffect(() => {
    if (env !== "local") {
      logMessage({
        name: `${widgetName} Setup`,
        feature: `ic web-component ${widgetName}`,
        event: `${widgetName}.init`,
        description: `Initialisation of the ic web-component ${widgetName}`,
        env,
      });
    }
  }, [env, widgetName]);
  return null;
};

export default WidgetTracker;
