import * as React from "react";
import { AccountWidget } from "../common/typings/accounts.typings";
import { Select } from "@sgbs-ui/core";
import { getClientById, getClientsByIds, searchAccounts } from "../api/accounts/accounts.api";
import { mapToAccountWidget, mapToListAccountWidget } from "../common/mappers/account.mapper";
import { FetchFn, useSgConnectFetch } from "@sg-widgets/react-core";
import { CRMClientScope } from "../common/sgConnectScopes";
import { renderAccount } from "../common/components/renderAccount";
import { InvalidFeedback } from "../common/components/InvalidFeedback/InvalidFeedback";
import { AccountDisplayOption, CommonPickerProps, CreateLinkMode } from "../common/typings";
import { useSingleSelectPicker } from "../common/hooks/useSingleSelectPicker";
import { useGenerateId } from "../common/hooks/useGenerateId";
import { ClientDto } from "../api/accounts/accounts.api.typings";
import { isEmpty, size } from "lodash-es";
import { useFetchGeneric } from "../common/hooks/useFetchGeneric";
import { useFetchSuggestions } from "../common/hooks/useFetchSuggestions";
import { useFetchHistory } from "../common/hooks/useHistory";

const ACCOUNT_SEARCH_FIELDS = ["name", "rctId", "bdrLegalId"];
const PICKER_NAME = "ic-account-single-picker";

interface Props extends CommonPickerProps<AccountWidget | null> {
  // iCId or CommercialBdrId
  selectedId?: string;
  // if selectedId is a CommercialBdrId, searchByBdrCommercialId should be true. In this case, the first search result match will be selected
  searchByBdrCommercialId?: boolean;
  readonlyId?: string;
  withFunds?: boolean;
  displayOption?: AccountDisplayOption;
  levels?: string[];
  legalOnly?: boolean;
  suggestionIds?: string[];
  accountsIds?: string[];
  createLinkMode?: CreateLinkMode;
  createActionText?: string;
  autofocus?: boolean;
  useHistory?: boolean;
  onCreateLinkClicked?: (term?: string) => void;
  onClearSuggestions?: (items: AccountWidget[]) => void;
  onRemoveSuggestion?: (item: AccountWidget) => void;
}

const SingleAccountPicker: React.FC<Props> = ({
  selectedId,
  readonlyId,
  maxResultCount,
  withFunds = false,
  displayOption = "none",
  searchByBdrCommercialId = false,
  levels,
  onReady,
  onChange,
  onClearSuggestions,
  onRemoveSuggestion,
  emitMode,
  legalOnly,
  suggestionIds,
  accountsIds,
  createLinkMode,
  createActionText,
  onCreateLinkClicked,
  autofocus,
  useHistory = false,
  ...props
}) => {
  const fetch = useSgConnectFetch(CRMClientScope).fetch as FetchFn;
  const pickerId = useGenerateId(props.id);

  const fetchById = React.useCallback(
    (iCIdOrBdrId: string): Promise<AccountWidget | null> => {
      if (searchByBdrCommercialId) {
        return searchAccounts(fetch, iCIdOrBdrId, { levels, withFunds, displayOption, legalOnly })
          .then(clients => {
            return size(clients) > 0 ? mapToAccountWidget(clients[0], displayOption) : null;
          })
          .catch(() => null);
      }

      return getClientById(fetch, iCIdOrBdrId, { levels, withFunds, displayOption, legalOnly })
        .then(client => mapToAccountWidget(client, displayOption))
        .catch(() => null);
    },
    [searchByBdrCommercialId, fetch, levels, withFunds, displayOption]
  );

  const fetchAccounts = React.useCallback(
    (term: string): Promise<AccountWidget[]> => {
      return searchAccounts(fetch, term, {
        pageSize: maxResultCount,
        withFunds,
        levels,
        displayOption,
        legalOnly,
      }).then((accounts: ClientDto[]) => {
        return mapToListAccountWidget(accounts, displayOption);
      });
    },
    [fetch, maxResultCount, withFunds, levels, displayOption]
  );

  const fetchAccountsByIds = React.useCallback((accountsIdsFromHistory? : string[]): Promise<AccountWidget[]> => {
    return getClientsByIds(fetch, accountsIds ?? accountsIdsFromHistory ?? []).then((accountsResults: ClientDto[]) => {
      return mapToListAccountWidget(accountsResults, displayOption, !!accountsIdsFromHistory?.length);
    });
  }, [accountsIds, fetch, displayOption]);

  const { suggestions } = useFetchSuggestions<AccountWidget>(
    () =>
      getClientsByIds(fetch, suggestionIds ?? []).then(suggestionAccountsDto =>
        mapToListAccountWidget(suggestionAccountsDto, displayOption, true)
      ),
    suggestionIds
  );

  const [selectedAccount, readOnlyAccounts, onSelect] = useSingleSelectPicker<AccountWidget>(
    fetchById,
    emitMode,
    onChange,
    onReady,
    selectedId,
    readonlyId
  );

  const [accounts, isLoading, hasError, errorMessage, onTermChange, term] = useFetchGeneric<AccountWidget>(
    fetchAccounts,
    fetchAccountsByIds,
    ACCOUNT_SEARCH_FIELDS,
    accountsIds
  );

  const [history, setHistory, removeItemFromHistory, clearHistory] = useFetchHistory(
    fetchAccountsByIds,
    PICKER_NAME,
    useHistory
  );
  return (
    <>
      <Select.AsyncSingleSelect<AccountWidget>
        id={pickerId}
        items={isEmpty(accounts) ? (!term ? suggestions.concat(history) : []) : accounts}
        selectedItem={selectedAccount}
        // TODO: fix readOnlyItems to take a single item instead of an array
        readOnlyItems={readOnlyAccounts}
        idField="id"
        isSuggestionField="isSuggestion"
        labelField="fullCommercialName"
        iconName="business"
        placeholder={props.placeholder}
        errorMessage={errorMessage}
        inError={hasError || props.inError || !!errorMessage}
        onTermChange={onTermChange}
        onChange={item => {
          onTermChange("");
          if (item) {
            setHistory([item?.id]);
          }
          onSelect(item);
        }}
        isLoading={isLoading}
        keepOrder={false}
        size={props.size as never}
        isOutline={props.outline}
        disabled={props.disabled}
        showCreateAction={createLinkMode && createLinkMode !== "none"}
        createActionText={createActionText}
        autofocus={autofocus}
        onCreatClick={onCreateLinkClicked}
        noResultMessage={"No results found. Try another account."}
        onClearSuggestions={(items: AccountWidget[]) => {
          clearHistory();
          onClearSuggestions?.(items);
        }}
        onRemoveSuggestion={(item: AccountWidget) => {
          removeItemFromHistory([item.id]);
          onRemoveSuggestion?.(item);
        }}
      >
        {renderAccount}
      </Select.AsyncSingleSelect>
      {props.inError && <InvalidFeedback errorMessage={props.errorMessage} />}
    </>
  );
};

export default SingleAccountPicker;
