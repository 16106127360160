import * as React from "react";

export const useFetchHistory = <TResult>(
  fetchByIds: (ids: string[]) => Promise<TResult[]>,
  pickerName: string,
  useHistory = false
): [TResult[], (ids?: string[]) => void, (ids?: string[]) => void, () => void] => {
  const [history, setHistory] = React.useState<TResult[]>([]);
  const [historyIds, setHistoryIds] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (useHistory) {
      const data = getHistory();
      data?.length
        ? fetchByIds(data).then(results => {
            results
              .sort((a: any, b: any) => data.indexOf(a.id) - data.indexOf(b.id))
              .forEach((result: any) => (result.isSuggestion = true));
            setHistory(results);
          })
        : setHistory([]);
    }
  }, [useHistory, historyIds]);

  const useSetHistory = (ids?: string[]): void => {
    if (useHistory) {
      const history = getHistory();
      let data: string[] = history?.length ? history : [];
      if (ids) {
        data = data.filter(d => !ids.includes(d));
        const concatIds = ids.concat(data);
        setHistoryIds(concatIds);
        window.localStorage.setItem(`${pickerName}-history`, JSON.stringify(concatIds));
      }
    }
  };

  const useRemoveItemFromHistory = (ids?: string[]): void => {
    if (useHistory) {
      const history = getHistory();
      const data: string[] = history?.length ? history : [];
      if (ids) {
        const idsToKeep = data.filter(d => !ids.includes(d));
        setHistoryIds(idsToKeep);
        window.localStorage.setItem(`${pickerName}-history`, JSON.stringify(idsToKeep));
      }
    }
  };

  const useClearHistory = (): void => {
    if (useHistory) {
      setHistory([]);
      setHistoryIds([]);
      window.localStorage.removeItem(`${pickerName}-history`);
    }
  };
  const getHistory = (): string[] => JSON.parse(window.localStorage.getItem(`${pickerName}-history`) ?? "{}");

  return [history, useSetHistory, useRemoveItemFromHistory, useClearHistory];
};
