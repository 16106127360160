import { ClientDto, DomainNameDto, privateClientDto } from "./accounts.api.typings";
import { compact, isEmpty, map } from "lodash-es";
import { createRepository } from "../api";
import { sanitizeSearchTerm } from "../../utils/strings/stringUtils";
import { ClientParams, mapToAccountApiParam } from "./accounts.api.mapper";
import { FetchFn } from "@sg-widgets/platform-api";
import { CONTACT_DB_HEADERS } from "../api.constants";
import { allSettled } from "../api.utils";

const contactDbClientUrl = "/1.0/sg/clients";
const crmClientUrl = "/2.0/clients";
const privateClientUrl = "/private/0.0/clients";

export const fetchClientByBdrLegalId = (
  fetch: FetchFn,
  bdrLegalId,
  queryParams: Partial<ClientParams>
): Promise<ClientDto | null> => {
  const params = { clientLegal: bdrLegalId, sort: "smartSort", ...mapToAccountApiParam(queryParams) };
  return createRepository(fetch)
    .get<ClientDto[]>(crmClientUrl, params)
    .then(accounts => (accounts?.length > 0 ? accounts[0] : null));
};

export const searchAccounts = (
  fetch: FetchFn,
  term: string,
  queryParams: Partial<ClientParams> = {}
): Promise<ClientDto[]> => {
  const params = { search: sanitizeSearchTerm(term), sort: "smartSort", ...mapToAccountApiParam(queryParams) };
  return createRepository(fetch).get<ClientDto[]>(crmClientUrl, params);
};

export const getClientById = (fetch: FetchFn, id: string, params: Partial<ClientParams> = {}): Promise<ClientDto> =>
  createRepository(fetch).get<ClientDto>(`${crmClientUrl}/${id}`, mapToAccountApiParam(params));

export const getClientsByIds = (
  fetch: FetchFn,
  ids: string[],
  params: Partial<ClientParams> = {}
): Promise<ClientDto[]> => {
  if (isEmpty(ids)) {
    return Promise.resolve([]);
  }
  return allSettled(map(ids, id => getClientById(fetch, id, params))).then(results =>
    compact(
      map(results, result => {
        if (result.status === "fulfilled") {
          return result.value;
        }
        return null;
      })
    )
  );
};

export const getDomainByClientId = (fetch: FetchFn, id: string): Promise<{ domains: DomainNameDto[] }> =>
  createRepository(fetch, CONTACT_DB_HEADERS).get<{ domains: DomainNameDto[] }>(`${contactDbClientUrl}/${id}/domains`, {
    pageSize: 500,
  });

export const getPrivateClientById = (fetch: FetchFn, id: string): Promise<privateClientDto> =>
  createRepository(fetch).get<privateClientDto>(`${privateClientUrl}/${id}`);
