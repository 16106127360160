export const diacriticsMap = [
  {
    base: "a",
    letters: /[àáâãäå]/g,
  },
  {
    base: "A",
    letters: /[ÀÁÂÃÄÅ]/g,
  },
  {
    base: "ae",
    letters: /[æ]/g,
  },
  {
    base: "AE",
    letters: /[Æ]/g,
  },
  {
    base: "c",
    letters: /[ç]/g,
  },
  {
    base: "C",
    letters: /[Ç]/g,
  },
  {
    base: "n",
    letters: /[ñ]/g,
  },
  {
    base: "N",
    letters: /[Ñ]/g,
  },
  {
    base: "oe",
    letters: /[œ]/g,
  },
  {
    base: "OE",
    letters: /[Œ]/g,
  },
  {
    base: "e",
    letters: /[èéêë]/g,
  },
  {
    base: "E",
    letters: /[ÈÉÊË]/g,
  },
  {
    base: "i",
    letters: /[ìíîï]/g,
  },
  {
    base: "I",
    letters: /[ÌÍÎÏ]/g,
  },
  {
    base: "o",
    letters: /[òóôõö]/g,
  },
  {
    base: "O",
    letters: /[ÒÓÔÕÖ]/g,
  },
  {
    base: "u",
    letters: /[ùúûü]/g,
  },
  {
    base: "U",
    letters: /[ÙÚÛÜ]/g,
  },
  {
    base: "y",
    letters: /[ýÿ]/g,
  },
  {
    base: "Y",
    letters: /[ÝŸ]/g,
  },
];
