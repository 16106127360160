import * as React from "react";
import { widgetize, WidgetPropsMapping } from "@sg-widgets/react-core";
import { convertToBoolOrUndefined } from "../utils/strings/stringify";
import WidgetTracker from "../common/components/WidgetTracker";
import SingleAccountPicker from "./AccountSingleSelectPicker";
import { AccountOutputDto, AccountWidget } from "../common/typings/accounts.typings";
import { WithSGB4 } from "../common/components/WithSGB4";
import { PropMapping } from "@sg-widgets/react-core/lib/props";
import { SGBSSize } from "@sgbs-ui/core";
import { mapToSingleAccountOutput } from "../common/mappers/account.output.mapper";
import { AccountDisplayOption, CommonPickerProps, CreateLinkMode, EmitOnChangeMode } from "../common/typings";
import { ACCOUNT_PLACEHOLDER, CREATE_LINK_MODE, EMIT_MODE, MAX_RESULT_COUNT, SIZE } from "../common/constants";
import { SgDashboardProps, SgDashboardWidgetProps } from "../common/sgDashboard";
import { getInitialSingleSelection, notifyChangeToSgDashboard, useEmitOnMount } from "../common/hooks/useUserSelection";
import { useCreateLinkClicked } from "../common/hooks/useCreateLinkClicked";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { defaultQueryClientOptions } from "@ic-anywhere/ic-dal";

const TAG = "ic-account-single-picker";

export interface Props extends CommonPickerProps<AccountOutputDto, string>, SgDashboardProps {
  selectedId?: string;
  readonlyId?: string;
  levels?: string[];
  withFunds?: string;
  displayOption?: AccountDisplayOption;
  legalOnly?: string;
  suggestionIds?: string[];
  accountsIds?: string[];
  createLinkMode?: CreateLinkMode;
  createActionText?: string;
  autofocus?: string;
  useHistory?: string;
  onCreateLinkClicked?: (term?: string) => void;
  onClearSuggestions?: (items: AccountWidget[]) => void;
  onRemoveSuggestion?: (item: AccountWidget) => void;
}

export const AccountSinglePickerWidget: React.FC<Props> = ({
  id,
  selectedId,
  readonlyId,
  levels,
  inError,
  errorMessage,
  withFunds,
  onChange,
  onClearSuggestions,
  onRemoveSuggestion,
  onReady,
  maxResultCount = MAX_RESULT_COUNT,
  placeholder = ACCOUNT_PLACEHOLDER,
  size = SIZE,
  displayOption,
  disabled,
  outline,
  emitMode = EMIT_MODE,
  // sg dashboard
  userSelection,
  setUserSelection,
  emitState,
  theme,
  legalOnly,
  suggestionIds,
  createLinkMode = CREATE_LINK_MODE,
  createActionText,
  autofocus,
  accountsIds,
  useHistory,
  onCreateLinkClicked,
}) => {
  const handleOnSelectionChange = (account: AccountWidget | null): void => {
    notifyChangeToSgDashboard({ userSelection, setUserSelection, emitState }, account);
    onChange?.(mapToSingleAccountOutput(account));
  };
  useEmitOnMount(userSelection, emitMode, () => onChange?.(mapToSingleAccountOutput(null)));
  const initSelection = getInitialSingleSelection(userSelection, selectedId);

  const handleOnCreateLinkClicked = useCreateLinkClicked(createLinkMode, "prospects", onCreateLinkClicked);
  const queryClient = new QueryClient({ defaultOptions: defaultQueryClientOptions });

  return (
    <WithSGB4 theme={theme}>
      <QueryClientProvider client={queryClient}>
        <SingleAccountPicker
          id={id}
          searchByBdrCommercialId={userSelection?.selectedSearchBy === "commercial"}
          maxResultCount={maxResultCount}
          placeholder={placeholder}
          selectedId={initSelection}
          suggestionIds={suggestionIds}
          accountsIds={accountsIds}
          onCreateLinkClicked={handleOnCreateLinkClicked}
          onClearSuggestions={onClearSuggestions}
          onRemoveSuggestion={onRemoveSuggestion}
          createLinkMode={createLinkMode}
          createActionText={createActionText}
          readonlyId={readonlyId}
          levels={levels}
          emitMode={emitMode}
          inError={convertToBoolOrUndefined(inError)}
          outline={convertToBoolOrUndefined(outline)}
          disabled={convertToBoolOrUndefined(disabled)}
          errorMessage={errorMessage}
          withFunds={convertToBoolOrUndefined(withFunds)}
          onChange={handleOnSelectionChange}
          onReady={onReady}
          size={size}
          displayOption={displayOption}
          legalOnly={convertToBoolOrUndefined(legalOnly)}
          autofocus={convertToBoolOrUndefined(autofocus)}
          useHistory={convertToBoolOrUndefined(useHistory)}
        />
        <WidgetTracker widgetName={TAG} />
      </QueryClientProvider>
    </WithSGB4>
  );
};

widgetize(
  TAG,
  AccountSinglePickerWidget,
  {
    id: WidgetPropsMapping.asString(),
    autofocus: WidgetPropsMapping.asString(),
    suggestionIds: WidgetPropsMapping.asObject(),
    accountsIds: WidgetPropsMapping.asObject(),
    createLinkMode: WidgetPropsMapping.asString() as PropMapping<Props, CreateLinkMode>,
    createActionText: WidgetPropsMapping.asString(),
    maxResultCount: WidgetPropsMapping.asNumber(),
    placeholder: WidgetPropsMapping.asString(),
    disabled: WidgetPropsMapping.asString(),
    outline: WidgetPropsMapping.asString(),
    size: WidgetPropsMapping.asString() as PropMapping<Props, SGBSSize>,
    displayOption: WidgetPropsMapping.asString() as PropMapping<Props, AccountDisplayOption>,
    inError: WidgetPropsMapping.asString(),
    errorMessage: WidgetPropsMapping.asString(),
    selectedId: WidgetPropsMapping.asString(),
    readonlyId: WidgetPropsMapping.asString(),
    levels: WidgetPropsMapping.asObject(),
    emitMode: WidgetPropsMapping.asString() as PropMapping<Props, EmitOnChangeMode>,
    withFunds: WidgetPropsMapping.asString(),
    useHistory: WidgetPropsMapping.asString(),
    onChange: WidgetPropsMapping.asEventEmitter("changed"),
    onReady: WidgetPropsMapping.asEventEmitter("ready"),
    onClearSuggestions: WidgetPropsMapping.asEventEmitter("clear-suggestions"),
    onRemoveSuggestion: WidgetPropsMapping.asEventEmitter("remove-suggestion"),
    legalOnly: WidgetPropsMapping.asString(),
    onCreateLinkClicked: WidgetPropsMapping.asEventEmitter("create-link-clicked"),
    ...SgDashboardWidgetProps,
  },
  { neverInjectGlobalCss: true }
);
