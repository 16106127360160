import * as React from "react";

interface Props {
  errorMessage?: string;
  isWarning?: boolean;
}

export const InvalidFeedback: React.FC<Props> = ({ errorMessage, isWarning = false }: Props) => (
  <div className={`${isWarning ? "warning-feedback" : "invalid-feedback"} d-block`}>{errorMessage}</div>
);
