import { IStringifyOptions, stringify } from "qs";
import { fromPairs, includes, isArray, toPairs } from "lodash-es";
import { isStringEmpty } from "./stringUtils";

export const stringifyUrlOptions: IStringifyOptions = { arrayFormat: "comma", encode: true };

export const stringifyLists = (object: Record<string, any>, ignoreKeys?: string[]): Record<string, any> => {
  const attributes = toPairs(object).map(([key, value]) =>
    isArray(value) && !includes(ignoreKeys, key) ? [key, value.join(",")] : [key, value]
  );
  return fromPairs(attributes);
};

export const stringifyParams = (params: Record<string, any>): string =>
  params ? `?${stringify(params, stringifyUrlOptions)}` : "";

export const convertToBoolOrUndefined = (value?: string): boolean | undefined =>
  !isStringEmpty(value) ? value?.trim() === "true" : undefined;