import { ApiRepository } from "@ic-anywhere/ic-dal";
import { useWidgetBusData } from "@sg-widgets/react-core";
import { getEnvironment } from "../common/environment";
import { getWidgetsLogger } from "./api.utils";

const useContactDbRepository = (): ApiRepository => {
  const sgConnectToken = useWidgetBusData<string>("sg-connect.access-token");

  const env = getEnvironment();
  const logger = getWidgetsLogger(env);

  return ApiRepository.ContactDb({ token: sgConnectToken ?? null, env, logger });
};

export default useContactDbRepository;
