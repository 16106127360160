import * as React from "react";
import { isEmpty } from "lodash-es";

export const useFetchSuggestions = <TResult>(
  fetchByIds: () => Promise<TResult[]>,
  suggestionIds?: string[]
): {
  suggestions: TResult[];
} => {
  const [suggestions, setSuggestions] = React.useState<TResult[]>([]);

  React.useEffect(() => {
    if (!isEmpty(suggestionIds)) {
      fetchByIds().then(setSuggestions);
    } else {
      setSuggestions([]);
    }
  }, [suggestionIds]);

  return {
    suggestions,
  };
};
