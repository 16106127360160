import React from "react";
import { isStringEmpty } from "../../utils/strings/stringUtils";
import { generateUID } from "@sgbs-ui/core";

export const useGenerateId = (initialId?: string): string =>
  React.useMemo(() => {
    if (initialId === undefined || isStringEmpty(initialId)) {
      return generateUID("picker");
    }
    return initialId;
  }, [initialId]);
