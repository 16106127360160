import React from "react";
import { AccountWidget } from "../typings/accounts.typings";

export const renderAccount = ({ fullCommercialName, isInMyPortfolio }: AccountWidget): React.ReactElement => {
  return (
    <div>
      {fullCommercialName}
      {isInMyPortfolio && <i className="icon text-info h-100 ml-2">{"account_balance_wallet"}</i>}
    </div>
  );
};
