import { WidgetPropsMapping } from "@sg-widgets/react-core";
import { PropMapping } from "@sg-widgets/react-core/lib/props";
import { Props } from "../ic-account-single-picker/ic-account-single-picker";
import { head, size } from "lodash-es";
import { Environment } from "@ic-anywhere/ic-utils";

export type SgBootstrapMode = "standard" | "dark";

export interface UserSelection {
  selectedIds?: string[];
  // indicate the type of the selected ids. "commercial": for commercialBrdId, "id": for iCId
  // defaults to "id"
  selectedSearchBy?: "commercial" | "id";
}

export interface SgDashboardProps {
  userSelection?: UserSelection;
  setUserSelection?: (value?: UserSelection) => void;
  emitState?: (newState?: { detail: UserSelection }) => void;
  theme?: SgBootstrapMode;
}

// See: https://sgithub.fr.world.socgen/DT-Widgets/sg-widgets-react-sample/tree/master/src/lesson-05-property-setter
// to understand how web component properties are binded to react props.
export const SgDashboardWidgetProps: WidgetPropsMapping<SgDashboardProps> = {
  userSelection: WidgetPropsMapping.asObject({
    description: "userSelection from SgDashboard",
  }),
  setUserSelection: WidgetPropsMapping.asPropertySetter("userSelection"),
  emitState: WidgetPropsMapping.asEventEmitter("state-changed", {
    description: "Internal state changed, used to tell Sg Dashboard something changed in the widget",
  }),
  theme: WidgetPropsMapping.asString() as PropMapping<Props, SgBootstrapMode>,
};

// setUserSelection: change web-component internal state to meet the last selection
// emitState: Notify sg-dashboard that the selection has changed
export const handleUserSelection = (props: SgDashboardProps): void => {
  props.setUserSelection?.(props.userSelection);
  props.emitState?.({ detail: props.userSelection ?? { selectedIds: [] } });
};

export const getSgDashboardSelectedId = (userSelection: UserSelection | undefined): string | undefined => {
  return size(userSelection?.selectedIds) > 0 ? head(userSelection?.selectedIds) : undefined;
};

const inferSgConnectEnvironment = (fallback: Environment) => {
  const sgConnectEndpoint: string | undefined = window["_environment"]?.sgConnectEndpoint || undefined;
  if (sgConnectEndpoint) {
    const sgConnectEnv = sgConnectEndpoint.includes("-hom") ? "homologation" : "production";
    if (sgConnectEnv && sgConnectEnv === "production") {
      // route dev/uat with production token to iso
      return "iso";
    } else if (sgConnectEnv && sgConnectEnv === "homologation") {
      return "homologation";
    }
  }
  return fallback;
};

// Sg Dashboard environments https://developer.sgmarkets.com/docs/sgdashboard/how-to/home-pages.html
export const getSgDashboardEnvironment = (hostname: string | undefined): Environment | null => {
  switch (hostname) {
    case "dashboard.sgmarkets.com":
      return "production";
    case "sg-dashboard-dev.fr.world.socgen":
    case "sg-dashboard-uat.fr.world.socgen":
      return inferSgConnectEnvironment("iso");
    case "sg-dashboard-dev2.fr.world.socgen":
    case "sg-dashboard-uat2.fr.world.socgen":
      return inferSgConnectEnvironment("homologation");
    default:
      return null;
  }
};
