import React from "react";
import { map } from "lodash-es";
import { SgBootstrapMode } from "../sgDashboard";

const iconsUrl = "https://shared.sgmarkets.com/sg-bootstrap/v4/icons/index.min.css";
const bootstrapUrl = "https://shared.sgmarkets.com/sg-bootstrap/v4/4.x/sg-bootstrap-standard.min.css";
const bootstrapDarkUrl = "https://shared.sgmarkets.com/sg-bootstrap/v4/4.x/sg-bootstrap-dark.min.css";

export interface Props {
  theme?: SgBootstrapMode;
  extraUrls?: string[];
  children?: any;
}
export const WithSGB4: React.FC<Props> = ({ children, extraUrls = [], theme }) => (
  <span>
    <style type="text/css">
      {map(theme === "dark" ? [bootstrapDarkUrl, iconsUrl] : [bootstrapUrl, iconsUrl], url => `@import url(${url});\n`)}
      {map(extraUrls, url => `@import url(${url});\n`)}
    </style>
    {children}
  </span>
);
