import { createLogger, Environment, Level } from "@dcc-cli/cli-web-logger";

export type WidgetEnv = "local" | "development" | "homologation" | "iso" | "production";

export interface LogMessage {
  name: string;
  feature: string;
  event: string;
  description: string;
  env: WidgetEnv;
}

const mapWidgetEnvToLoggerEnv = (environment: WidgetEnv): Environment => {
  switch (environment) {
    case "local":
    case "development":
    case "homologation":
      return "HOM";
    case "iso":
      return "ISO";
    case "production":
      return "PRD";
    default:
      return "HOM";
  }
};

const createWebLogger = (environment: WidgetEnv): ReturnType<typeof createLogger> =>
  createLogger("IC", mapWidgetEnvToLoggerEnv(environment));

export const logError = (environment: WidgetEnv, error: Error): void => {
  createWebLogger(environment).technical("UnknownError", `${error.message}${error.stack}`, Level.Error);
};

export const logMessage = ({ feature, event, name, description, env }: LogMessage): void => {
  const component = "ic-web-component";
  createWebLogger(env)
    .setCustom("feature", feature)
    .setCustom("event", event)
    .setCustom("app", component)
    .setCustom("host", location.host)
    .functional(name, description, component);
};
