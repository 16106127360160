import { FetchFn } from "@sg-widgets/platform-api";
import { IcHttpRequestHeader } from "./api.typings";
import { POST_JSON_HEADERS } from "./api.constants";
import { omit, reduce } from "lodash-es";
import { buildUrlForWidgets } from "./api.utils";

export interface Repository {
  get: <T>(url: string, params?: Record<string, any>) => Promise<T>;
  post: <T>(url: string, params?: Record<string, any>) => Promise<T>;
  put: <T>(url: string, params?: Record<string, any>) => Promise<T>;
  delete: <T>(url: string, params?: Record<string, any>) => Promise<T>;
  upload: <T>(url: string, params?: Record<string, any>) => Promise<T>;
}

export const getResult = <T>(response: Response): Promise<T> => {
  if (!response.ok) {
    return response.text().then(text =>
      Promise.reject({
        ...JSON.parse(text ?? "{}"),
        status: response?.status,
        message: response?.statusText,
        etag: response?.headers?.get?.("etag"),
      })
    );
  }
  if (response.status === 204) {
    return {} as Promise<T>;
  }
  return response.text().then(text => (text ? JSON.parse(text) : {}));
};

const safeFetch = async (fetch: FetchFn, url: string, init?: RequestInit): Promise<Response> => {
  try {
    return await fetch(url, init);
  } catch (error) {
    if (error instanceof Error) {
      const explanation = error.message.match(/\.*is not a function\.*/)
        ? "Error while fetching data. Please check your authentication, scopes and window.SGWTWidgetConfiguration"
        : "Error while fetching data.";
      console.error("%s : %o", explanation, error);
      throw new Error(explanation);
    }
    throw new Error();
  }
};

export const createRepository = (fetch: FetchFn, headers: IcHttpRequestHeader = {}): Repository => ({
  get: async <T>(url: string, params?: Record<string, any>): Promise<T> =>
    getResult<T>(await safeFetch(fetch, buildUrlForWidgets(url, headers, params), { headers: { ...headers } as any })),
  post: async <T>(url: string, data: any, params?: Record<string, string>): Promise<T> => {
    return getResult<T>(
      await safeFetch(fetch, buildUrlForWidgets(url, headers, params), {
        method: "POST",
        body: JSON.stringify(data),
        headers: { ...headers, ...POST_JSON_HEADERS } as any,
      })
    );
  },
  put: async <T>(url: string, data: any, params?: Record<string, string>): Promise<T> => {
    return getResult<T>(
      await safeFetch(fetch, buildUrlForWidgets(url, headers, params), {
        method: "PUT",
        body: JSON.stringify(data),
        headers: { ...headers, ...POST_JSON_HEADERS } as any,
      })
    );
  },
  delete: async <T>(url: string): Promise<T> => {
    return getResult<T>(
      await safeFetch(fetch, buildUrlForWidgets(url, headers), {
        method: "DELETE",
        headers: { ...headers, ...POST_JSON_HEADERS } as any,
      })
    );
  },
  upload: async <T>(url: string, files: any, params?: Record<string, string>): Promise<T> => {
    const data = reduce(
      files,
      (acc, file) => {
        acc.append("file", file);
        return acc;
      },
      new FormData()
    );
    const options = {
      method: "POST",
      body: data,
      headers: omit<any>(headers, "Content-Type"),
    };
    // fetch set automatically boundary in multipart/form-data

    return getResult<T>(await safeFetch(fetch, buildUrlForWidgets(url, headers, params), options));
  },
});
