import { Environment } from "@ic-anywhere/ic-utils";

import { getSgDashboardEnvironment } from "./sgDashboard";

export const getEnvironment = (): Environment => {
  const widgetConfEnv = (window["SGWTWidgetConfiguration"]?.environment as Environment) ?? "homologation";

  return getSgDashboardEnvironment(window?.location?.hostname) ?? widgetConfEnv;
};

export const isSgDashboardEnv = (): boolean => !!getSgDashboardEnvironment(window?.location?.hostname);
