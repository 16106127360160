import { CreateLinkMode } from "../typings";
import React from "react";
import { getVnextCreateLink } from "../../api/api.utils";

export const useCreateLinkClicked = (
  createLinkMode: CreateLinkMode,
  entity: "contacts" | "prospects",
  onCreateLinkClicked?: (term?: string) => void
): ((term?: string) => void) => {
  return React.useCallback(
    (term?: string): void => {
      if (createLinkMode === "emit" && onCreateLinkClicked) {
        onCreateLinkClicked(term);
      }
      if (createLinkMode === "redirect") {
        window.location.assign(getVnextCreateLink(entity));
      }
    },
    [createLinkMode, entity, onCreateLinkClicked]
  );
};
