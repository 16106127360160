import { SgDashboardProps, UserSelection } from "../sgDashboard";
import * as React from "react";
import { head, isEmpty, map } from "lodash-es";
import { EmitOnChangeMode } from "../typings";
import { isSgDashboardEnv } from "../environment";

// In order to init widgets depending on ic selector in sg-dashboard, we need to emit --changed event on widget mount
// Should emit a new --changed event only. Don't call setUserSelection here
export function useEmitOnMount(
  userSelection: UserSelection | undefined,
  emitMode: EmitOnChangeMode,
  onChange: () => void
) {
  React.useEffect(() => {
    if (emitMode === "always" && isEmpty(userSelection?.selectedIds)) {
      onChange?.();
    }
  }, []);
}

export function getInitialMultiSelection<T>(
  userSelection: UserSelection | null | undefined,
  selectedIds: string[] | undefined
): string[] | undefined {
  // In sg-dashboard, use userSelection and ignore selectedIds
  // outside sg-dashboard, use selectedIds and ignore userSelection
  return isSgDashboardEnv() ? userSelection?.selectedIds : selectedIds;
}

export function getInitialSingleSelection<T>(
  userSelection?: UserSelection | null,
  selectedId?: string
): string | undefined {
  // In sg-dashboard, use userSelection and ignore selectedId
  // outside sg-dashboard,  use selectedId and ignore userSelection
  return isSgDashboardEnv() ? head(userSelection?.selectedIds) : selectedId;
}

export function notifyMultiChangeToSgDashboard<T extends { id: string }>(
  { userSelection, setUserSelection, emitState }: SgDashboardProps,
  items: T[] | undefined = []
): void {
  const selection: UserSelection = { selectedIds: map(items, ({ id }) => id) ?? [] };
  emitNewSelection({ setUserSelection, emitState, userSelection }, selection);
}

export function notifyChangeToSgDashboard<T extends { id: string; bdrCommercialId?: string }>(
  { userSelection, setUserSelection, emitState }: SgDashboardProps,
  item?: T | null
): void {
  const ItemId: string | undefined =
    userSelection?.selectedSearchBy === "commercial" ? item?.bdrCommercialId : item?.id;

  const newUserSelection: UserSelection = { ...userSelection, selectedIds: ItemId ? [ItemId] : [] };
  emitNewSelection({ userSelection, emitState, setUserSelection }, newUserSelection);
}

function emitNewSelection({ userSelection, setUserSelection, emitState }: SgDashboardProps, selection: UserSelection) {
  setUserSelection?.(selection);
  emitState?.({ detail: selection });
}
