import { SGBSSize } from "@sgbs-ui/core";
import { ContactDisplayOption, CreateLinkMode, EmitOnChangeMode } from "./typings";

export const MAX_RESULT_COUNT = 250;
export const PLACEHOLDER = "Search and select";
export const ACCOUNT_PLACEHOLDER = "Search a name, BDR ID or RCT ID";
export const SIZE: SGBSSize = "lg";
export const CREATE_LINK_MODE: CreateLinkMode = "none";
export const EMIT_MODE: EmitOnChangeMode = "user";
export const CONTACT_DISPLAY_OPTION: ContactDisplayOption = "none";
export const DEBOUNCE_SEARCH_TIME = 300;
export const FLAG_ICON_CSS_FILE = "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/css/flag-icon.min.css";
