const commonScopes = ["openid", "profile", "mail"];
const privateCRMService = "api.manage-ict.v1";
const privateTeamsService = "api.contacts-private-services.v1";
const contactCRMService = "api.manage-commercial-contacts.v2";
const activitiesCRMService = "api.manage-commercial-activities.v2";
const dealCRMService = "api.manage-deals-in-pipeline.v2";
const clientCRMService = "api.manage-commercial-clients.v2";
const contactSGMService = "api.manage-contacts.v1";
const contactSGMWrite = "api.manage-contacts.write";
const thirdPartiesServices = "api.get-third-parties.v1";
const anywherePrivateService = "api.anywhere-private-services.v1";

export const AccountViewerScope = [...commonScopes, privateCRMService, clientCRMService, contactSGMService];
export const SGMContactScope = [...commonScopes, contactSGMService];
export const CRMClientScope = [...commonScopes, clientCRMService];
export const CRMContactScope = [...commonScopes, contactCRMService];
export const CRMPrivateScope = [...commonScopes, privateCRMService];
export const CRMDealScope = [...commonScopes, dealCRMService];
export const CRMSearchScope = [...commonScopes, clientCRMService, contactCRMService];
export const CRMActivitiesScope = [...commonScopes, activitiesCRMService];
export const ContactCreatorScope = [...commonScopes, contactSGMWrite, contactSGMService];
export const SGMContactWriteScope = [...commonScopes, contactSGMWrite];
export const MaestroSearchScope = [...commonScopes, thirdPartiesServices];
export const CreateMandateScope = [...commonScopes, privateTeamsService, contactSGMService, contactSGMWrite];
export const TablePowersScope = [...commonScopes, contactSGMService, privateCRMService];
export const ClientTeamViewScope = [...commonScopes, privateCRMService, clientCRMService];
export const LastActivitiesScope = [...commonScopes, clientCRMService, contactSGMService, activitiesCRMService];

export const sgConnectScopes = [
  ...commonScopes,
  privateTeamsService,
  privateCRMService,
  contactCRMService,
  clientCRMService,
  contactSGMService,
  contactSGMWrite,
  dealCRMService,
  activitiesCRMService,
  anywherePrivateService,
  thirdPartiesServices,
];
