import { AccountOutputDto, AccountWidget } from "../typings/accounts.typings";

export const mapToSingleAccountOutput = (account: AccountWidget | null): AccountOutputDto => {
  // for legacy compliance (used in sg-dashboard)
  const legacyProps = {
    bdrCommercialId: account?.bdrCommercialId,
    bdrCommercialLevel: account?.bdrCommercialLevel,
    bdrLegalId: account?.bdrLegalId,
    bdrLegalLevel: account?.bdrLegalLevel,
    icId: account?.icId,
    name: account?.name,
  };
  if (account) {
    return { account, ...legacyProps };
  }
  return { account: null };
};
