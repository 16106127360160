import { AccountProperty, ClientQueryParams, CommonClientQueryParams } from "./accounts.api.typings";
import { MAX_RESULT_COUNT } from "../../common/constants";
import { pickBy } from "lodash-es";
import { AccountDisplayOption } from "../../common/typings";

export const DEFAULT_CLIENT_API_FIELDS: AccountProperty[] = [
  "clientIds",
  "country",
  "portfolio",
  "mainAddress",
  "typology",
  "customerRepresentative",
  "tieringList"
];

export interface ClientParams extends CommonClientQueryParams {
  displayOption?: AccountDisplayOption;
  legalOnly?: boolean;
}

export const mapToAccountApiParam = ({
  coveredByEmail,
  properties = DEFAULT_CLIENT_API_FIELDS,
  pageSize = MAX_RESULT_COUNT,
  typologyIds,
  levels,
  countryIds,
  onlyWithActivity,
  city,
  levelIds,
  listIds,
  applyInheritOnList,
  withFunds,
  displayOption = "none",
  legalOnly = false,
}: ClientParams): ClientQueryParams => {
  const withBdrId = displayOption !== "none";
  return pickBy(
    {
      onlyWithActivity,
      coveredByEmail,
      pageSize,
      city,
      properties: !withBdrId ? properties : [...properties, "bdrId"],
      typologyIds,
      levels,
      countryIds,
      listIds,
      levelIds,
      applyInheritOnList,
      withFunds,
      clientTypes: legalOnly ? "legal,prospect" : undefined,
    },
    value => value !== undefined
  );
};
